package tta.destinigo.talktoastro.shared.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import tta.destinigo.talktoastro.shared.platform.loadJsonFile

@Composable
fun MobileWithCountryCodeEditTextSingle(
    modifier: Modifier = Modifier,
    selectedCountry: String,
    mobileText: String,
    label: @Composable () -> Unit,
    placeHolder: @Composable () -> Unit,
    supportingText: @Composable () -> Unit,
    onMobileChange: (String) -> Unit,
    onCountryCodeChange: (String) -> Unit,
    isError: Boolean = false,
) {
    var isDropdownVisible by remember { mutableStateOf(false) }
    var searchQuery by remember { mutableStateOf("") }

    val countries = loadCountryData()

    val filteredCountries = countries.filter {
        it.name.contains(searchQuery, ignoreCase = true) || it.dailCode.contains(searchQuery)
    }

    Box(modifier = modifier) {
        OutlinedTextField(
            value = mobileText,
            onValueChange = { input ->
                if (input.all { it.isDigit() }) {
                    onMobileChange(input)
                }
            },
            label = { label() },
            placeholder = { placeHolder() },
            singleLine = true,
            prefix = {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.clickable { isDropdownVisible = true }
                ) {
                    Text(text = selectedCountry, style = MaterialTheme.typography.bodyMedium.copy(
                        fontWeight = FontWeight.Bold
                    ))
                    Icon(
                        imageVector = Icons.Default.ArrowDropDown,
                        contentDescription = "Select Country Code",
                        modifier = Modifier.padding(start = 4.dp)
                    )
                }
            },
            supportingText = {
                supportingText()
            },
            isError = isError, // You can replace with error state logic
            modifier = Modifier.fillMaxWidth(),
            colors = OutlinedTextFieldDefaults.colors(
                focusedContainerColor = Color.Transparent,
                unfocusedContainerColor = Color.Transparent,
                focusedBorderColor = Color(0xFF112B60),
                unfocusedBorderColor = Color.Gray
            ),
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Number)
        )

//         Dropdown for country code selection
        DropdownMenu(
            expanded = isDropdownVisible,
            onDismissRequest = { isDropdownVisible = false },
            modifier = Modifier.width(320.dp).background(Color.White).heightIn(400.dp)
        ) {
            // Search Field
            DropdownMenuItem(
                onClick = {},
                enabled = false,
                text = {
                    OutlinedTextField(
                        value = searchQuery,
                        onValueChange = { searchQuery = it },
                        label = { Text("Search") },
                        modifier = Modifier.fillMaxWidth().padding(8.dp)
                    )
                }
            )

            // Filtered countries list
            filteredCountries.forEach { country ->
                DropdownMenuItem(
                    onClick = {
                        onCountryCodeChange(country.dailCode)
                        isDropdownVisible = false
                    },
                    text = {
                        Row {
                            Text(text = country.emoji, style = MaterialTheme.typography.bodyMedium)
                            Spacer(modifier = Modifier.width(8.dp))
                            Text(text = "${country.name} (${country.dailCode})")
                        }
                    }
                )
            }

            // No results found
            if (filteredCountries.isEmpty()) {
                DropdownMenuItem(
                    onClick = {},
                    enabled = false,
                    text = {
                        Text(
                            text = "No results found",
                            modifier = Modifier.padding(8.dp),
                            style = MaterialTheme.typography.bodyMedium
                        )
                    }
                )
            }
        }
    }
}


@Serializable
data class CountryShared(
    @SerialName("name") val name: String,
    @SerialName("dial_code") val dailCode: String,
    @SerialName("emoji") val emoji: String,
    @SerialName("code") val code: String,
)

@Composable
fun loadCountryData(): List<CountryShared> {
    val jsonString = loadJsonFile("CountryCode.json")
    return Json.decodeFromString(jsonString)
}
