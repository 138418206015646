package tta.destinigo.talktoastro.shared.persentation

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.DatePicker
import androidx.compose.material3.DatePickerDefaults
import androidx.compose.material3.DatePickerDialog
import androidx.compose.material3.DisplayMode
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.TimePicker
import androidx.compose.material3.TimePickerDefaults
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.material3.rememberTimePickerState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import tta.destinigo.talktoastro.shared.theme.primaryColor

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CommonDatePickerDialog(
    modifier: Modifier = Modifier,
    selectedDate: (Long?) -> Unit,
    initialDisplayMode: DisplayMode = DisplayMode.Picker,
    onDialogDismiss: () -> Unit
) {
    val datePickerState = rememberDatePickerState(initialDisplayMode = initialDisplayMode)

    DatePickerDialog(
        modifier = modifier,
        onDismissRequest = { onDialogDismiss.invoke() },
        confirmButton = {
            TextButton(
                onClick = {
                    if (datePickerState.selectedDateMillis != null) {
                        selectedDate.invoke(datePickerState.selectedDateMillis)
                    } else {
                        selectedDate.invoke(null) // Handle cases where no date is selected
                    }
                    onDialogDismiss.invoke()
                }
            ) {
                Text("OK", color = primaryColor)
            }
        },
        dismissButton = {
            TextButton(onClick = { onDialogDismiss.invoke() }) {
                Text("CANCEL", color = primaryColor)
            }
        }
    ) {
        DatePicker(
            state = datePickerState,
            colors = DatePickerDefaults.colors(
                selectedDayContainerColor = primaryColor,
                selectedDayContentColor = Color.White,
                selectedYearContainerColor = primaryColor,
                selectedYearContentColor = Color.White,
                todayContentColor = primaryColor,
                todayDateBorderColor = primaryColor
            )
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CommonTimePickerDialog(
    modifier: Modifier = Modifier,
    selectedTime: (Int, Int) -> Unit,
    is24Hour: Boolean = false,
    onDialogDismiss: () -> Unit
) {
    val timePickerState = rememberTimePickerState(is24Hour = is24Hour)

    Dialog(
        onDismissRequest = { onDialogDismiss.invoke() }
    ) {
        Surface(
            modifier = modifier,
            shape = MaterialTheme.shapes.medium,
            tonalElevation = 8.dp
        ) {
            Column(
                modifier = Modifier
                    .padding(16.dp)
                    .fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                TimePicker(
                    colors = TimePickerDefaults.colors(
                        periodSelectorSelectedContainerColor = primaryColor,
                        periodSelectorSelectedContentColor = Color.White,
                        periodSelectorUnselectedContainerColor = Color.White,
                        periodSelectorUnselectedContentColor = primaryColor
                    ),
                    state = timePickerState
                )

                Spacer(modifier = Modifier.height(16.dp))

                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    TextButton(onClick = { onDialogDismiss.invoke() }) {
                        Text("CANCEL", color = primaryColor)
                    }

                    Spacer(modifier = Modifier.width(8.dp))

                    TextButton(
                        onClick = {
                            val hour = timePickerState.hour
                            val minute = timePickerState.minute

                            selectedTime.invoke(hour, minute)
                            onDialogDismiss.invoke()
                        }
                    ) {
                        Text("OK", color = primaryColor)
                    }
                }
            }
        }
    }
}
