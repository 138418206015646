package tta.destinigo.talktoastro.shared.persentation


import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Box
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.sp

@Composable
fun AutoSizeText(
    text: String,
    color: Color = Color.Unspecified,
    modifier: Modifier = Modifier,
    minFontSize: TextUnit = 12.sp,  // Minimum font size
    maxFontSize: TextUnit = 16.sp,  // Maximum font size
    style: TextStyle = TextStyle.Default,
    maxLines: Int = 1
) {
    var textSize by remember { mutableStateOf(maxFontSize) }
    var shouldResize by remember { mutableStateOf(true) }

    Box(
        modifier = modifier
            .onSizeChanged { shouldResize = true }  // Trigger resize on size change
    ) {
        Text(
            text = text,
            style = style.copy(fontSize = textSize, fontWeight = FontWeight.SemiBold),
            maxLines = maxLines,
            softWrap = true,
            overflow = TextOverflow.Clip,  // Prevent ellipsis while resizing
            onTextLayout = { textLayoutResult ->
                if (shouldResize && textLayoutResult.hasVisualOverflow) {
                    shouldResize = false
                    // Gradually reduce the text size
                    if (textSize > minFontSize) {
                        textSize = (textSize.value - 1).coerceAtLeast(minFontSize.value).sp
                    }
                }
            }
        )
    }
}

@Composable
fun TextWithStrikeThrough(
    modifier: Modifier,
    text: String,
    strikethroughColor: Color = Color.Gray.copy(alpha = 0.8f),
    textColor: Color = Color.Gray.copy(alpha = 0.8f),
    fontSize: Int = 14
) {
    Box(modifier = modifier) {
        Text(
            text = text,
            style = TextStyle(
                color = textColor,
                fontSize = fontSize.sp,
                fontWeight = FontWeight.Bold
            ),
        )
        Canvas(modifier = Modifier.matchParentSize()) {
            val textLayoutResult = textLayoutResult(text, fontSize.sp.toPx())
            drawLine(
                color = strikethroughColor,
                start = Offset(0f, textLayoutResult.centerY),
                end = Offset(size.width, textLayoutResult.centerY),
                strokeWidth = 1f
            )
        }
    }
}


private fun textLayoutResult(text: String, fontSizePx: Float): TextLayoutResult {
    // A helper function to get the text layout information
    // You might need to adjust this to get the accurate text layout details
    return TextLayoutResult(text, fontSizePx, 0f, 0f)
}

// Dummy class to simulate text layout information
private data class TextLayoutResult(
    val text: String,
    val fontSizePx: Float,
    val baseline: Float,
    val height: Float
) {
    val centerY: Float
        get() = baseline + (height / 2)
}

