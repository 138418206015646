package tta.destinigo.talktoastro.shared.local

import co.touchlab.kermit.Logger
import com.russhwolf.settings.set

object TokenProvider {

    private const val AUTH_KEY_TOKEN_NEW = "new_token_winyway"
    private const val AUTH_KEY_TOKEN_REFRESH = "refresh_token_winyway"

    fun setNewToken(token: String){
        Logger.d("SET_TOKEN New token Set-> $token")
        val oldToken = settings.getString(AUTH_KEY_TOKEN_NEW,"")
        Logger.d("SET_TOKEN Old token -> $oldToken")
        settings.putString(AUTH_KEY_TOKEN_NEW,token)
        Logger.d("SET_TOKEN after token saved - ${settings.getString(AUTH_KEY_TOKEN_NEW,"")}")
    }

    fun getNewToken(): String{
        return settings.getString(AUTH_KEY_TOKEN_NEW,"")
    }

    fun getRefreshToken(): String{
        return settings.getString(AUTH_KEY_TOKEN_REFRESH,"")
    }

    fun setRefreshToken(token: String){
        settings[AUTH_KEY_TOKEN_REFRESH] = token
    }

    fun clearTokens() {
        settings.remove(AUTH_KEY_TOKEN_NEW)
        settings.remove(AUTH_KEY_TOKEN_REFRESH)
        settings.clear()
    }

}