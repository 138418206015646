package tta.destinigo.talktoastro.shared.data

import kotlinx.serialization.Serializable

@Serializable
data class RequestCategory(
    val id: Int,
    val name: String?,
    val image: String?,
    val image_url: String?,
    val subcategories: List<SubCategoriesModel>?
)
