package tta.destinigo.talktoastro.shared.theme

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp

object Scale {

    val WINDOW_WIDTH_SIZE_COMPACT = Modifier.fillMaxWidth()
    val WINDOW_WIDTH_SIZE_MEDIUM = Modifier.fillMaxWidth(0.85f)
    val WINDOW_WIDTH_SIZE_EXPEND = Modifier.fillMaxWidth(0.80f)
    val WINDOW_WIDTH_SIZE_ELSE = Modifier.fillMaxWidth(0.70f)


    val GLOBAL_ICON_SIZE = 20.dp
    val GLOBAL_ICON_SIZE_SMALL = 16.dp
    val GLOBAL_ICON_SIZE_MIN = 15.dp
    val GLOBAL_ICON_SIZE_MAX = 24.dp
    val GLOBAL_CALL_AND_CHAT_ICON_SIZE = 70.dp
    val ACTION_BAR_BUTTON_SIZE = 35.dp
    val WINYWAY_LOGO_SIZE = 40.dp

    val VERTICAL_PADDING = 10.dp
    val VERTICAL_PADDING_SMALL = 5.dp
    val HORIZONTAL_PADDING = 16.dp
    val HORIZONTAL_PADDING_SMALL = 8.dp

    val ELEVATION_SMALL = 1.dp
    val ELEVATION_MEDIUM = 2.dp
    val ELEVATION_LARGE = 4.dp
    val ELEVATION_EXTRA_LARGE = 8.dp

    val CORNER_EXTRA_SMALL = 4.dp
    val CORNER_EXTRA_MIN = 2.dp
    val CORNER_SMALL = 6.dp
    val CORNER_MEDIUM = 8.dp
    val CORNER_LARGE = 12.dp
    val CORNER_EXTRA_LARGE = 16.dp
}
