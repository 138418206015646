package tta.destinigo.talktoastro.shared.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shadow
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getIsLogin
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.primaryColor

enum class ButtonStatus(
    val label: String,
    val buttonColor: Color,
    val iconColor: Color,
    val enabled: Boolean,
    val icon: ImageVector
) {
    ONLINE_CHAT(
        "CHAT",
        // Color(0xFF6200EE),
        Color(0xFF1B98F0),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),   // Purple for online
    ONLINE_CALL(
        "CALL",
        //Color(0xFF28A745),
        Color(0xFF25d366),
        Color.White,
        true,
        Icons.Filled.Call
    ),   // Purple for online
    BUSY_CHAT(
        "BUSY",
        Color(0xFFEF3636),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),     // Orange for busy
    BUSY_CALL(
        "BUSY",
        Color(0xFFEF3636),
        Color.White,
        true,
        Icons.Filled.Call
    ),     // Orange for busy
    OFFLINE_CHAT(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.AutoMirrored.Filled.Chat
    ),   // Gray for offline
    OFFLINE_CALL(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.Filled.Call
    )   // Gray for offline
}

@Composable
fun ConsultationButton(
    modifier: Modifier = Modifier,
    status: ButtonStatus,
    onClick: () -> Unit,
    isTextVisible: Boolean
) {
    Button(
        onClick = {
            if ((status.label == "BUSY").not()) onClick.invoke()
        },
        colors = ButtonDefaults.buttonColors(containerColor = status.buttonColor),
        enabled = status.enabled,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.elevatedButtonElevation(
            defaultElevation = Scale.ELEVATION_MEDIUM,
            pressedElevation = Scale.ELEVATION_LARGE,
            disabledElevation = 0.dp
        ),
        contentPadding = PaddingValues(0.dp),
        modifier = modifier
    ) {
        BoxWithConstraints(
            contentAlignment = Alignment.Center
        ) {
            // Dynamically adjust font size based on button width
            val fontSize = if (maxWidth < 250.dp) 12.sp else 16.sp
            val iconSize =
                if (maxWidth < 250.dp) Scale.GLOBAL_ICON_SIZE_SMALL else Scale.GLOBAL_ICON_SIZE

            Row(
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Icon(
                    imageVector = status.icon,
                    contentDescription = status.label,
                    tint = status.iconColor,
                    modifier = Modifier.size(iconSize)
                )

                if (isTextVisible) {
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = status.label,
                        color = status.iconColor,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        fontSize = fontSize
                    )
                }
            }
        }
    }
}


@Composable
fun ChatConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        chatStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CHAT
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CHAT

        else -> ButtonStatus.ONLINE_CHAT
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}

@Composable
fun CallConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        callStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CALL
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CALL

        else -> ButtonStatus.ONLINE_CALL
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}


@Composable
fun ExpertPriceRow(
    isFreeCallApplicable: Boolean,
    callPerMinutePrice: String?,
    expertPrice: Int,
    winywayPrice: Int,
    flashPrice: String?,
    horizontalArrangement: Arrangement.Horizontal
) {
    val totalCallPerMinutePrice = callPerMinutePrice ?: (expertPrice + winywayPrice).toString()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth(),
        horizontalArrangement = horizontalArrangement
    ) {
        // New User Tag
        if (isFreeCallApplicable || getIsLogin().not()) {
//            Box(modifier = Modifier
//                .background(primaryColor)
//                .padding(horizontal = 4.dp, vertical = 2.dp), contentAlignment = Alignment.Center){
//
//            }

            Box(
                modifier = Modifier
                    //.align(Alignment.TopEnd) // Position the tag at the top-right corner
                    .padding(4.dp)
                    .background(
                        primaryColor.copy(alpha = 0.8f),
                        RoundedCornerShape(Scale.CORNER_EXTRA_MIN)
                    )
                    .padding(horizontal = 6.dp, vertical = 2.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "New User",
                    style = MaterialTheme.typography.labelSmall,
                    color = Color.White
                )
            }

            val notLoginOrNewUserCase = flashPrice ?: callPerMinutePrice
            Spacer(modifier = Modifier.width(2.dp))
            Text(
                text = "${initializeCurrency()}$notLoginOrNewUserCase/Min",
                color = Color.Black,
                fontSize = 14.sp,
                modifier = Modifier.alpha(0.6f),
                style = TextStyle(
                    fontStyle = FontStyle.Italic,
                    textDecoration = TextDecoration.LineThrough
                )
            )
        } else {
            // Price Text
            if (isFreeCallApplicable) {
                FlashPrice(flashPrice, totalCallPerMinutePrice)
            } else {
                if (!flashPrice.isNullOrEmpty() && flashPrice != "0") {
                    FlashPrice(flashPrice, totalCallPerMinutePrice)
                } else {
                    Text(
                        text = "${initializeCurrency()}$totalCallPerMinutePrice/Min",
                        color = Color.Black,
                        fontSize = 14.sp
                    )
                }
            }
        }


    }
}

@Composable
fun FlashPrice(flashPrice: String?, totalCallPerMinutePrice: String) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.wrapContentWidth(),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = "${initializeCurrency()}$flashPrice/Min",
            color = Color.Black,
            fontSize = 14.sp
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = "${initializeCurrency()}$totalCallPerMinutePrice/Min",
            color = Color.Black,
            fontSize = 14.sp,
            modifier = Modifier.alpha(0.6f),
            style = TextStyle(
                fontStyle = FontStyle.Italic,
                textDecoration = TextDecoration.LineThrough
            )
        )
    }
}


/* Expert Profile Icon with Customize Ring according to value
* use modifier to modify
* This used in this project all place
* if you want to change in this than if will reflect all place*/
@Composable
fun ExpertProfileIcon(
    profileImageUrl: String?,
    modifier: Modifier = Modifier.size(30.dp),
    data: RequestConsultant? = null
) {

    val borderColor = when (data?.isAnyUniqueExpertApplicable()) {
        "Premium" -> Color(0xFFFFCB8E)
        "Trending" -> Color(0xFF1B98F0) // You can use different colors for different experts
        "Celebrity" -> Color(0xFF171616)// Same here for Celebrity
        "New" -> Color(0xFFe7404a)
        else -> Color.White // Default color when no applicable expert
    }

    Box(
        modifier = modifier, contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier.matchParentSize().border(
                width = 3.dp, color = borderColor.copy(alpha = 0.4f), shape = CircleShape
                // width = 2.dp, color = Color.Gray.copy(alpha = 0.3f), shape = CircleShape
            ).clip(CircleShape).background(Color.White).padding(1.dp).clip(CircleShape)
        ) {
            AsyncImage(
                model = profileImageUrl,
                modifier = Modifier.size(85.dp).clip(CircleShape),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )
        }
    }
}


@Composable
fun HighlightingTag(expert: RequestConsultant) {

    val borderColor = when (expert.isAnyUniqueExpertApplicable()) {
        "Premium" -> Color(0xFFFFCB8E)
        "Trending" -> Color(0xFF1B98F0) // You can use different colors for different experts
        "Celebrity" -> Color(0xFF171616) // Same here for Celebrity
        "New" -> Color(0xFFe7404a)
        else -> Color.White // Default color when no applicable expert
    }

//    val borderIcon = when (expert.isAnyUniqueExpertApplicable()) {
//        "Premium" -> painterResource(Res.drawable.premium_background)
//        "Trending" -> painterResource(Res.drawable.trending_background)
//        "Celebrity" -> painterResource(Res.drawable.celebrity_background) // Add appropriate drawable
//        "New" -> painterResource(Res.drawable.new_expert_background)
//        else -> null // Add default drawable
//    }



    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier.size(width = 70.dp, height = 35.dp) // Adjust dimensions as needed
    ) {
//        if (borderIcon != null) {
//            Icon(
//                painter = borderIcon,
//                contentDescription = null,
//                tint = borderColor,
//                modifier = Modifier.fillMaxSize()
//            )
//        }

        expert.isAnyUniqueExpertApplicable()?.let {
            Text(
                text = it,
                color = Color.White,
                maxLines = 1,
                style = TextStyle(
                    fontSize = 13.sp,
                    fontWeight = FontWeight.Bold,
                    letterSpacing = 0.4.sp,
                    shadow = Shadow(
                        color = Color.Black.copy(alpha = 0.4f), // Subtle shadow for better visibility
                        offset = Offset(1f, 1f),
                        blurRadius = 2f
                    )
                ),
                modifier = Modifier.align(Alignment.Center) // Center-align the text over the icon
            )
        }
    }
}