package tta.destinigo.talktoastro.shared.local

import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import kotlinx.browser.window

actual val settings: Settings
    get() = StorageSettings()

//actual suspend fun saveChatMessage(
//    url: String,
//    chatId: String,
//    list: List<ChatMessage>,
//    chatType: String
//): String {
//    TODO("Not yet implemented")
//}

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun openWhatsApp(phoneNumber: String) {
    val url = "https://wa.me/$phoneNumber" // WhatsApp link format
    window.location.href = url // Open WhatsApp in the browser
}