package tta.destinigo.talktoastro.shared.persentation

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.ClickableText
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.shared.theme.primaryColor

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun LoginDialog(
    onLoginClicked: () -> Unit,
    onSignUpClicked: () -> Unit,
    onDismiss: () -> Unit
) {
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    val content: @Composable () -> Unit = {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                text = "Talk to Astro",
                style = MaterialTheme.typography.titleLarge.copy(
                    color = primaryColor,
                    fontWeight = FontWeight.Bold,
                    fontSize = 28.sp
                )
            )
            Spacer(modifier = Modifier.height(8.dp))
            Text(
                text = "You have to login to continue...\nLogin and get free Expert Consultation",
                style = MaterialTheme.typography.bodyLarge.copy(
                    textAlign = TextAlign.Center
                ),
                color = Color.Gray
            )
            Spacer(modifier = Modifier.height(24.dp))
            CommonButton(
                onClick = onLoginClicked,
                buttonText = "LOGIN",
                modifier = Modifier.fillMaxWidth()
            )
            Spacer(modifier = Modifier.height(16.dp))
            ClickableText(
                text = AnnotatedString("Don't have an account? Sign Up"),
                style = MaterialTheme.typography.bodyMedium.copy(
                    color = Color(0xFF6200EE),
                    fontWeight = FontWeight.Medium
                ),
                onClick = { onSignUpClicked.invoke() }
            )
        }
    }

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        ModalBottomSheet(
            sheetState = sheetState,
            containerColor = Color.White,
            onDismissRequest = {
                coroutineScope.launch {
                    sheetState.hide()
                    onDismiss.invoke()
                }
            },
            content = { content.invoke() }
        )
    } else {
        AlertDialog(
            onDismissRequest = { onDismiss.invoke() },
            text = content,
            containerColor = Color.White,
            confirmButton = {},
            dismissButton = {}
        )
    }
}

