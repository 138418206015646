package tta.destinigo.talktoastro.shared.persentation

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.StarHalf
import androidx.compose.material.icons.filled.Star
import androidx.compose.material.icons.filled.StarOutline
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.shared.theme.primaryColor

@Composable
fun DottedLineDivider(
    color: Color = Color.Gray.copy(0.6f),
    dotRadius: Float = 2f,
    spaceBetween: Float = 5f,
    modifier: Modifier = Modifier
        .fillMaxWidth()
        .height(1.dp)
) {
    Canvas(modifier = modifier) {
        val totalWidth = size.width
        var currentX = 0f

        while (currentX < totalWidth) {
            drawCircle(
                color = color,
                radius = dotRadius,
                center = Offset(currentX + dotRadius, size.height / 2)
            )
            currentX += 2 * dotRadius + spaceBetween
        }
    }
}

@Composable
fun StarRating(
    rating: Float, // Current rating
    maxRating: Int = 5, // Total stars
    modifier: Modifier = Modifier,
    starSize: Dp = 15.dp,
    filledStarColor: Color = primaryColor, // Gold color
    emptyStarColor: Color = Color.Gray
) {
    Row(modifier = modifier) {
        for (i in 1..maxRating) {
            val starIcon = when {
                i <= rating -> Icons.Default.Star // Fully filled star
                i - 0.5 <= rating -> Icons.AutoMirrored.Filled.StarHalf // Half-filled star
                else -> Icons.Default.StarOutline // Empty star
            }
            Icon(
                imageVector = starIcon,
                contentDescription = "Star $i",
                tint = if (i <= rating) filledStarColor else emptyStarColor,
                modifier = Modifier.size(starSize)
            )
        }
    }
}

