package tta.destinigo.talktoastro.shared.remote

import tta.destinigo.talktoastro.shared.platform.encodeBase64
import io.ktor.client.HttpClient


abstract class KtorApi {

    fun create(username: String, password: String): String {
        val credentials = "$username:$password"
        val base64Credentials = encodeBase64(credentials)
        return "Basic $base64Credentials"
    }

    val client: HttpClient by lazy { createHttpClient(null) }
    val astrologyClient by lazy {
        createHttpClient(
            "astrology"
        )
    }

}

// Expect function for creating the HTTP client
expect fun createHttpClient(customAuthorization: String?): HttpClient

