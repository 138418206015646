package tta.destinigo.talktoastro.shared.data

import kotlinx.serialization.Serializable

@Serializable
class CommonExpertInfo(
    val expertId: String? = null,
    val expertName: String,
    val expertProfileImageUrl: String?,
    val expertSkills: String?,
    val expertRating: Double? = null
)
