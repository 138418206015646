package tta.destinigo.talktoastro.shared.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestConsultant (
    val account_approval: Int? = null,
    val account_holder_name: String? = null,
    val account_number: String? = null,
    val account_type: String? = null,
    val year_of_experience: String? = null,
    val address: String? = null,
    val last_online_time: String? = null,
    val bank_name: String? = null,
    val cats: List<Cat>? = null,
    val education: List<Education>? = null,
    val profession: List<Profession>? = null,
//    var consultant_fav: ConsultantFav? = null,
    val certification: String? = null,
    val created_at: String? = null,
    val device_details: String? = null,
    val display_profession: Int? = null,
    val display_skills: Int? = null,
    val display_education_section: Int? = null,
    val display_profession_section: Int? = null,
    val email: String? = null,
    val first_name: String? = null,
    val full_name: String? = null,
    var is_favrouite: Int? = null,
    val gender: String? = null,
    val id: Int? = null,
    val ifsc_code: String? = null,
    val language: String? = null,
    val last_name: String? = null,
    val linkedin_url: String? = null,
    val login_device: String? = null,
    val mobile: String? = null,
    val notification_token: String? = null,
    val offer_letter: String? = null,
    val pan_image: String? = null,
    val pan_number: String? = null,
    val professional_approval: Int? = null,
    var consultant_rating: Double? = null,
    val profile_description: String? = null,
    val profile_image: String? = null,
    val profile_image_url: String? = null,
    val redirect_to: String? = null,
    val service_categories_description: String? = null,
    val service_type: String? = null,
    val skills: String? = null,
    val status: Int? = null,
    val consultant_price: Int? = null,
    val flash_flag: Int? = null,
    val flash_discount: Int? = null,
    val flash_price: Int? = null,
    val type: String? = null,
    val updated_at: String? = null,
    val user_pricings: List<UserPricing>? = null,
    val gallery_images: List<GalleryImages>? = null,
    var reviews: List<Review>? = null,
    val video: String? = null,
    val video_url: String? = null,
    val whatsapp_updates: Int? = null,
    val total_reviews_count: String? = null,
    val questions: List<Question>? = null,
    @SerialName("expert_flash_price") val expertFlashPrice: Float? = null,
    @SerialName("winyway_flash_price") val winywayFlashPrice: Float? = null,
    @SerialName("total_flash_price") val flashPrice: String? = null,
    @SerialName("is_free_call") val isFreeCall: Int? = null,
    @SerialName("callstatus") val callStatus: String? = null,
    @SerialName("chatstatus") val chatButtonStatus: String? = null,
   // @SerialName("call_per_minute_price") val callPerMinutePrice: String? = null,
    @SerialName("call_per_minute_price") val rawCallPerMinutePrice: String? = null,
    @SerialName("is_premium") val premiumExpert: String? = null,
    @SerialName("is_trending") val trendingExpert: String? = null,
    @SerialName("is_celebrity") val celebrityExpert: String? = null,
    @SerialName("is_notify") var isAvailableNotify: Int? = null,
    @SerialName("winyway_price") var winyway_price: Int? = null,
    @SerialName("expert_price") var expert_price: Int? = null,
    @SerialName("is_new") var isExpertNew: String?,
   // @SerialName("emergency_price") var emergencyPrice: String?,
    // Other properties remain the same
    @SerialName("flash_per_minute_price") private val rawFlashPerMinutePrice: String?,
    @SerialName("emergency_price") private val rawEmergencyPrice: String?,
    @SerialName("flash_international_price") private val rawFlashInternationalPrice: String?,
    @SerialName("is_chat") var isChat: Int? = null,
    @SerialName("is_call") var isCall: Int? = null,
    //@SerialName("flash_per_minute_price") val flashPerMinutePrice: String?,
    @SerialName("expert_availability") val ExpertAvailability:String?
) {
    val callPerMinutePrice : String? get() = formatToSingleDecimal(rawCallPerMinutePrice)
    val flashPerMinutePrice: String?
        get() = formatToSingleDecimal(rawFlashPerMinutePrice)

    val emergencyPrice: String?
        get() = formatToSingleDecimal(rawEmergencyPrice)

    val flashInternationalPrice: String?
        get() = formatToSingleDecimal(rawFlashInternationalPrice)

    fun isAvailableNotifyDone(): Boolean {
        return this.isAvailableNotify == 1
    }

//    fun consultantCallAvailability(): String? {
//        if(isCall.toString() == "1") return "Online"
//        if(isCall.toString() == "2") return "Busy"
//        if(isCall.toString()== "0") return "Offline"
//        return null
//    }

    fun consultantCallAvailability(): String? {
        if(callStatus == "Online") return "Online"
        if(callStatus == "Busy") return "Busy"
        if(callStatus== "Offline") return "Offline"
        return null
    }

//    fun consultantChatAvailability(): String? {
//        if(isChat.toString() == "1") return "Online"
//        if(isChat.toString() == "2") return "Busy"
//        if(isChat.toString()== "0") return "Offline"
//        return null
//    }

    fun consultantChatAvailability(): String? {
        if(chatButtonStatus == "Online") return "Online"
        if(chatButtonStatus == "Busy") return "Busy"
        if(chatButtonStatus == "Offline") return "Offline"
        return null
    }

    fun isAnyUniqueExpertApplicable(): String? {
        if (premiumExpert == "1") return "Premium"
        if (trendingExpert == "1") return "Trending"
        if (celebrityExpert == "1") return "Celebrity"
        if (isExpertNew == "1") return "New"
        return null
    }


//    fun isAnyUniqueExpertApplicableDrawable(): DrawableResource? {
//        if (premiumExpert == "1") return Res.drawable.
//        if (trendingExpert == "1") return "Trending"
//        if (celebrityExpert == "1") return "Celebrity"
//        return null
//    }
}
fun formatToSingleDecimal(value: String?): String? {
    return try {
        value?.toDoubleOrNull()?.let {
            (it * 10).toInt() / 10.0 // Multiply and divide to truncate at one decimal
        }?.toString()
    } catch (e: Exception) {
        null
    }
}




@Serializable
data class Cat(
    val cat: CatX? = null,
    val category_id: Int? = null,
    val created_at: String? = null,
    val id: Int? = null,
    val updated_at: String? = null,
    val user_id: Int? = null
)

@Serializable
data class UserPricing(
    val created_at: String? = null,
    val id: Int? = null,
    val mention_price: String? = null,
    val question_1: String? = null,
    val question_2: String? = null,
    val slot_duration: String? = null,
    val slot_for: String? = null,
    val updated_at: String? = null,
    val user_id: Int? = null
)

@Serializable
data class GalleryImages(
    val id: Int? = null,
    val expert_id: Int? = null,
    val images: String? = null,
    val created_at: String? = null,
    val updated_at: String? = null
)

@Serializable
data class CatX(
    val created_at: String? = null,
    val description: String? = null,
    val id: Int? = null,
    val name: String? = null,
    val parent_id: Int? = null,
    val status: Int? = null,
    val updated_at: String? = null
)

@Serializable
data class Review(
    val consultant_id: Int? = null,
    val created_at: String? = null,
    @SerialName("user_name") val userName: String? = null,
    val id: Int? = null,
    val rating: String? = null,
    val remarks: String? = null,
    val updated_at: String? = null,
    val user_id: Int? = null,
    val user: UserData? = null,
)

@Serializable
data class ConsultantFav(
    val consultant_id: Int? = null,
    val user_id: Int? = null
)

@Serializable
data class Education(
    val education: String? = null,
    val specialization: String? = null,
    val from: String? = null,
    val id: Int? = null,
    val till: String? = null,
    val user_id: Int? = null
)

@Serializable
data class Profession(
    val from: String? = null,
    val id: Int? = null,
    val organization_name: String? = null,
    val profession: String? = null,
    val till: String? = null,
    val user_id: Int? = null
)

@Serializable
data class Question(
    val created_at: String? = null,
    val id: Int? = null,
    val question: String? = null,
    val updated_at: String? = null,
    val user_id: Int? = null
)

@Serializable
data class UserData(
    val id: Int? = null,
    val full_name: String? = null,
    val profile_image_url: String? = null,
    val video_url: String? = null
)